@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@700&display=swap");

@font-face {
  font-family: 'Roboto';
  src: url('../src/assets/fonts/Roboto/Roboto-Regular.ttf ');
}


@font-face {
  font-family: 'Roboto';
  src: url('../src/assets/fonts/Roboto/Roboto-Light.ttf');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('../src/assets/fonts/Roboto/Roboto-Regular.ttf');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Roboto';
  src: url('../src/assets/fonts/Roboto/Roboto-Medium.ttf');
  font-weight: 500;
  font-style: normal;
}

body {
  margin: 0;
  line-height: normal;
  font-family: Roboto;
}