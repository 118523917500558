.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.arrow-toggler-active {
  transform: scaleX(-1);
  transition: transform 0.6s;
  position: absolute;
  top: 9px;
  left: -40px;
  width: 80px;
  height: 80px;
  cursor: pointer;
}

.arrow-toggler {
  transition: transform 0.6s;
  position: absolute;
  top: 9px;
  left: -40px;
  width: 80px;
  height: 80px;
  cursor: pointer;
}

.MuiDataGrid-root .MuiDataGrid-cell:focus-within {
  outline: none !important;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.c-app {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  min-height: 100vh;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0;
  /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
  -moz-appearance: textfield;
  /* Firefox */
}

.custom-btn {
  color: white !important;
  border-color: #EA4726 !important;
  background-color: #EA4726 !important;
}

.login-btn {
  color: white !important;
  border-color: #EA4726 !important;
  background-color: #EA4726 !important;
}

.c-app-login {
  background-color: #f6f6f6;
}

.c-app-default {
  min-height: 100vh;
  background-color: #F2F2F2;
}

.disable-text-selection {
  -moz-user-select: none;
  /* firefox */
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE*/
  user-select: none;
  /* Standard syntax */
}

.btn-login {
  background-color: #EA4726 !important;
  border-color: transparent !important;
}

.custom-shadow-lg {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.card {
  border-radius: 5px;
  -webkit-box-shadow: 0 1px 2.94px 0.06px rgb(4 26 55 / 16%);
  box-shadow: 0 1px 2.94px 0.06px rgb(4 26 55 / 16%);
  border: none;
  margin-bottom: 10px;
  overflow: hidden;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.card {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, .125);
  border-radius: 0.25rem;
}

.breadCrumbLine {
  border-bottom: 2px solid #616161;
  line-height: 5vh;
  border-color: #616161;
  max-width: 100%;
  justify-content: center;
}

.card-body {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 0.7rem 0.7rem 0.7rem 0.7rem;
}

.sidebar-footer,
.sidebar-header,
.sidebar-nav,
.sidebar-brand,
.sidebar-narrow {
  background: rgba(216, 216, 216, 1);
  display: flex;
  font-size: 0.8vw;



}

.sidebar-nav {
  overflow: hidden;
  text-wrap: wrap;
  text-align: center;
}

.MuiTableCell-head {
  font-weight: bold !important;

}

.block-ui-container {
  z-index: 1500 !important;
}

.sidebar {
  text-wrap: wrap;
  text-align: center;
  background: rgba(216, 216, 216, 1);
  position: fixed;
  margin: 0;
  padding: 0;
}


.sidebar-nav {
  display: flex;
  text-wrap: wrap;
  text-align: center;
  font-size: 0.8vw;
  text-decoration-line: underline;
}


.sidebar-nav .nav-link.active {
  text-align: center;
  font-size: 0.7vw;
  background-color: #f6f6f6;
  color: black !important;
  border-radius: 8px 0 0 8px;
  border-top: 1px solid #006ca6;
  border-bottom: 1px solid #006ca6;
  border-left: 1px solid #006ca6;
  margin-left: 5px;
  text-decoration-line: underline;
  padding: 7px;

}

.nav-icon,
.sidebar-nav .nav-link {
  color: black !important;
  font-family: 'Roboto';
  text-align: center;
  text-decoration-line: underline;
  font-size: 0.7vw;
  margin-left: 5px;
  margin-bottom: 10px;
  padding: 7px;

}



.nav-circle-top {

  width: 30px;
  height: 10px;
  shape-outside: circle();
  background: #BB391E;
  position: absolute;
  right: 0;
  top: 0;
  margin-top: -10px;
}


.hide-item {
  display: none;
}

.nav-item {
  position: relative;
  display: flex;
  flex-direction: column;
}

/* .nav-item, .nav-group {
  padding: 8px;
} */

.nav-link {

  overflow: hidden;
  display: flex;
  flex-direction: column;

}

/* F6F7F8 */
.custom-nav-item {
  position: relative;
  overflow: hidden;


}

.border-bottom {}

.custom-nav-item b:nth-child(1) {
  position: absolute;
  height: 20px;
  width: 100%;
  background: #fff;
  display: none;

}

.custom-nav-item b:nth-child(1)::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: #EA4726;
  border-bottom-right-radius: 20px;
}

.custom-nav-item b:nth-child(2) {
  position: absolute;
  height: 20px;
  width: 100%;
  background: #fff;
  display: none;
}

.custom-nav-item b:nth-child(2)::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: #EA4726;
  border-top-right-radius: 20px;
}

.nav-link.active>b:nth-child(1) {
  display: block;
}

.nav-link.active>b:nth-child(2) {
  display: block;
}

.simplebar-content-wrapper {
  height: 100% !important;
}



.custom-btn {
  color: white !important;
  border-color: #EA4726 !important;
  background-color: #EA4726 !important;
}

.pointer {
  cursor: pointer;
}




.offcanvas.show {
  transform: none !important;
}

.offcanvas-end {
  width: 550px !important;
}

::-webkit-scrollbar {
  /* Set width and height for WebKit browsers */
  width: 7px;
  height: 7px;
  /* Adjust as needed */
  background-color: #D9D9D9;

  /* Adjust as needed */
}

::-webkit-scrollbar-thumb {
  background-color: #006CA650;

}

::-webkit-scrollbar-thumb:hover {
  background-color: #006CA6;
}


::-webkit-scrollbar-thumb::before {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 20px;
  /* Adjust width of the image */
  height: 20px;
  /* Adjust height of the image */
}

::-webkit-scrollbar-button {
  display: none;
}

::-webkit-scrollbar-thumb:horizontal {
  /* Hide horizontal scrollbar thumb arrows */
  border-left: none;
  border-right: none;
}

::-webkit-scrollbar-thumb:vertical {
  /* Hide vertical scrollbar thumb arrows */
  border-top: none;
  border-bottom: none;
}